// common
/**
 * Get value with boundaries
 * @param {number} value desired value
 * @param {number} min min boundary
 * @param {number} max max boundary
 * @returns {number} calculated value
 * @example
 * minMax(15, 5, 10); // returns 10 (max boundary)
 */
export function minMax(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Simple object clone
 * @param {object} object object to clone
 * @returns {any} cloned object
 * @example
 * const clonedObject = cloneObject(originalObject);
 */
export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

// promise-based utils
/**
 * Await timeout
 * @param {number} [duration] timeout duration
 * @returns {Promise<void>} timeout promise
 * @example
 * await awaitTimeout(100);
 */
export function awaitTimeout(duration = 0) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

/**
 * Await request animation frame
 * @returns {Promise<void>} request animation frame promise
 * @example
 * await awaitRAF();
 */
export function awaitRAF() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

// em font-sizing
/**
 * Get element font size
 * @param {HTMLElement|string} [element] reference element or selector
 * @returns {number} element font-size
 */
export function getElementFz(element = document.body) {
  if (typeof element === 'string') {
    element = document.querySelector(element);
  }

  if (!element) return 0;

  const elementStyle = window.getComputedStyle(element);
  return parseFloat(elementStyle.fontSize);
}

/**
 * Get px value regarding element font-size
 * @param {number} pxValue desired px value
 * @param {HTMLElement|string} [contextElement] reference element or selector
 * @returns {number} calculated px value
 */
export function toResizedPx(pxValue, contextElement = document.body) {
  return (pxValue / 16) * getElementFz(contextElement);
}

/**
 * Get em value regarding element font-size
 * @param {number} pxValue desired px value
 * @param {number} [pxContext] px context
 * @returns {number} calculated px value
 */
export function toResizedEm(pxValue, pxContext = 16) {
  return pxValue / pxContext;
}

// preload images
/**
 * Preload image
 * @param {string} url image url
 * @returns {Promise<void>} upload promise
 */
export function preloadImage(url) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;

    image.addEventListener('load', resolve);
    image.addEventListener('error', resolve);
  });
}

/**
 * Preload images
 * @param {string[]} imageUrls image urls
 * @returns {Promise<Awaited<void>[]>} upload promises
 */
export function preloadImages(imageUrls = []) {
  const preloads = Array.from(imageUrls).map(preloadImage);

  return Promise.all(preloads);
}

// preload fonts
/**
 * Get font preload list
 * @param {string} path font path
 * @param {number[]} weights font weights
 * @param {string} baseUrl site base url
 * @returns {{rel: string, href: string, as: string, type: string, crossorigin: true}[]} font preload list
 */
export function getFontPreloadList({ path, weights }, baseUrl = '/') {
  return weights.map((weight) => ({
    rel: 'preload',
    href: `${baseUrl}fonts/${path}${weight}.woff2`,
    as: 'font',
    type: 'font/woff2',
    crossorigin: true,
  }));
}

/**
 * Get fonts preload list
 * @param {object[]} fontsList font params list
 * @param {string} fontsList.path font path
 * @param {number[]} fontsList.weights font weights
 * @param {string} [baseUrl] site base url
 * @returns {{rel: string, href: string, as: string, type: string, crossorigin: true}[]} fonts preload list
 */
export function getFontsPreloadList(fontsList, baseUrl = '/') {
  return fontsList.reduce(
    (result, { path, weights }) => [
      ...result,
      ...getFontPreloadList({ path, weights }, baseUrl),
    ],
    [],
  );
}

export const formatValues = {
  /**
   * @description метод для форматирования номера телефона
   * @param phoneValue {string}
   * @returns {string} Возвращает номер в виде +1 (111) 111-11-11
   */
  phone: (phoneValue) => {
    if (!phoneValue) return '';

    const phoneToString = phoneValue.toString();
    const digitsOnly = phoneToString.replace(/\D/g, '');

    const matchData = digitsOnly.match(
      /(?<kod>[0-9]{1})(?<operator>[0-9]{3})(?<firstpart>[0-9]{3})(?<secondpart>[0-9]{2})(?<lastpart>[0-9]{2})/,
    );

    if (!matchData) return '';

    const { groups } = matchData;
    const { kod, operator, firstpart, secondpart, lastpart } = groups;

    return `+${kod} (${operator}) ${firstpart}-${secondpart}-${lastpart}`;
  },

  /**
   * @description метод для форматирования номера телефона
   * @param phoneValue {string}
   * @returns {string} Возвращает номер в виде 11111111111
   */
  phoneOnlyNumbers: (phoneValue) => {
    if (!phoneValue) return '';

    // Преобразование phoneValue в строку и удаление всех нечисловых символов
    return phoneValue.toString().replace(/\D/g, '');
  },
};

export function toFormData(dataObject) {
  const formData = new FormData();

  Object.entries(dataObject).forEach(([key, value]) => {
    let formatValue = value;

    if (typeof formatValue !== 'object') {
      formatValue = value.toString();
    }

    if (Array.isArray(formatValue)) {
      return formatValue.forEach((formatItem, index) =>
        formData.append(`${key}[${index}]`, formatItem),
      );
    }

    formData.append(key, formatValue);
  });

  return formData;
}

/**
 * Отправка аналитики
 * @param {Object} data - Объект данных для добавления в "dataLayer".
 */
export function dLayer(data) {
  if (window?.dataLayer) {
    window.dataLayer.push(data);
  }
}

/**
 * Determines if the current device supports touch interactions.
 * This is typically used to enable or disable touch-specific event handling in applications.
 *
 * @return {boolean} True if the device supports touch interactions, otherwise false.
 */
export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

/**
 * Отправляет событие в Yandex Metrika, если `ym` доступен в `window`.
 *
 * @function
 * Ожидаемые аргументы в вызове:
 * 1. `id` (number): Идентификатор счетчика Yandex Metrika.
 * 2. `method` (string): Метод для вызова в Yandex Metrika:
 *    - `'reachGoal'` — для отслеживания цели, требует имени цели.
 *    - `'params'` — для отправки произвольных параметров, требует объекта параметров.
 * 3. `...args`:
 *    - Если `method` — `'reachGoal'`:
 *        - `goalName` (string): имя цели.
 *        - `goalParams` (object, опционально): дополнительные параметры для цели.
 *    - Если `method` — `'params'`:
 *        - `params` (object): объект с произвольными параметрами.
 *
 * @example
 * // Отправка цели с именем 'goalName' без дополнительных параметров:
 * ymAnalytic(12345678, 'reachGoal', 'goalName');
 *
 * @example
 * // Отправка цели с дополнительными параметрами:
 * ymAnalytic(12345678, 'reachGoal', 'goalName', { order_id: 123, value: 500 });
 *
 * @example
 * // Отправка произвольных параметров:
 * ymAnalytic(12345678, 'params', { order_id: 123, user_status: 'new' });
 */
export function ymAnalytic() {
  if (window?.ym && typeof window?.ym === 'function') {
    window.ym(...arguments);
  }
}
